let BASE_URL = null
let HOSTNAME_RADAR = null
const { hostname } = window.location

switch (hostname) {
  case 'tracker.wenuwork.cl':
    BASE_URL = 'https://ws.wenuwork.cl'
    HOSTNAME_RADAR = 'https://radar.wenuwork.cl'
    break
  case 'tracker-dev.wenuwork.cl':
    BASE_URL = 'https://api-dev.wenuwork.cl'
    HOSTNAME_RADAR = 'https://radar-dev.wenuwork.cl'
    break
  case 'localhost':
    BASE_URL = 'http://localhost:4040'
    HOSTNAME_RADAR = 'http://localhost:8080'
    break
  default:
    break
}

export const URL_RADAR = HOSTNAME_RADAR
export const FETCH_DATA = `${BASE_URL}/tracker/data-origin`
export const COMPANY_LIST = `${BASE_URL}/tracker/company-list`
