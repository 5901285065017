import React, { useState, useEffect } from "react";
import "./App.css";
import BarChart from "./component/BarChart.js";
import CompanyList from "./component/CompanyList.js";
import Caledar from "./component/Calendar.js";
import { subDays, addDays } from "date-fns";
import { FETCH_DATA, URL_RADAR } from "./constants/api";
import dayjs from 'dayjs'

import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'

// Extend dayjs with plugins
dayjs.extend(utc)
dayjs.extend(tz)

const Chart = (data) => {
  let result = [];

  if (data) {
    for (const objeto in data) {
      result.push(
        <div key={`ID-${objeto}`}>
          <p>
            {
              (data[objeto].location || "")
              + " (id: "
              + data[objeto].name
              + ")"
            }
          </p>
          <BarChart data={data[objeto].data} />
        </div>
      );
    }
    return result;
  }
};

function App() {
  const [data, setData] = useState([]);
  const [dataToGraph, setDataToGraph] = useState([]);
  const [dataToGraphFinal, setDataToGraphFinal] = useState([]);
  const [hasAccessToken, setAccessToken] = useState();
  const [IdInCompany, setIdInCompany] = useState([]);
  const [dateRange, setDateRange] = useState([
    subDays(new Date(), 7),
    addDays(new Date(), 0),
  ]);
  const [startDate, endDate] = dateRange;
  const [isMobile, setIsMobile] = useState(false);
  const difHoraria = new Date().getTimezoneOffset() / -60;

  const formatWithTimeZone = (date, addHour = false, hourToAdd = 0) => {
    //const date3 = date.getTime();
    const dateTemp = new Date(date.getTime());

    const date1 = dateTemp.setHours(
      dateTemp.getHours() + addHour ? hourToAdd : -difHoraria
    );
    const horaLocal = new Date(date1);

    if (!addHour) {
      return (
        horaLocal.getFullYear().toString() +
        (horaLocal.getMonth() + 1).toString().padStart(2, "0") +
        horaLocal.getDate().toString().padStart(2, "0") +
        horaLocal.getHours().toString().padStart(2, "0")
      );
    } else {
      return (
        ":" +
        horaLocal.getFullYear().toString() +
        (horaLocal.getMonth() + 1).toString().padStart(2, "0") +
        horaLocal.getDate().toString().padStart(2, "0") +
        horaLocal.getHours().toString().padStart(2, "0")
      );
    }
  };

  const fechaInicio = startDate ? formatWithTimeZone(startDate) : null;

  const fechaFin = endDate ? formatWithTimeZone(addDays(endDate, 1)) : null;

  const fetchData = async () => {
    const { message } = IdInCompany
    if (message) {
      const dataArr = [];

      for (const item of message) {
        const [wenuId, location] = item;
        const url = `${FETCH_DATA}?id=${wenuId}&beginDate=${wenuId}01:${fechaInicio}&endDate=${wenuId}01:${fechaFin}&location=${location}`;

        try {
          const response = await fetch(url, {
            headers: { Authorization: `Bearer ${hasAccessToken}` },
          });
          const data = await response.json();
          dataArr.push(data);
        } catch (error) {
          console.error('Error fetching data:', error);
          // Handle the error as needed, possibly pushing a placeholder or error object to dataArr
        }
      }

      await setData(dataArr);
    }
  };

  const formatDate = (valor) => {
    // Extraer la información de la fecha y hora del valor
    const input = valor.split(":")[1]
    const dateString = dayjs.utc(input, 'YYYYMMDDHH');
    const date = dateString.tz('America/Santiago').format('DD-MM-YYYY HH:mm')
    return date
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1000) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  useEffect(() => {
    if (dateRange[1]) {
      fetchData();
    }
  }, [IdInCompany]);

  useEffect(() => {
    if (dateRange[1]) {
      fetchData();
    }
  }, [dateRange]);

  useEffect(() => {
    const result = {};

    data.forEach((item, index) => {
      if (item && item.hasOwnProperty("id")) {
        const dataArr = item.message.map((msg) => {
          const fecha = formatDate(msg._id);

          return {
            fecha: fecha,
            _id: msg._id,
            lastMeasure: msg.lastMeasure,
            memory: msg.memory,
            faltantes: msg.missing,
          };
        });

        result[item.id] = {
          name: item.id,
          data: dataArr,
          location: item.location,
        };
      }
    });
    setDataToGraph(result);
  }, [data]);

  useEffect(() => {
    const endDateEqualToday =
      endDate.getFullYear() === new Date().getFullYear()
      && endDate.getMonth() === new Date().getMonth()
      && endDate.getDate() === new Date().getDate()
        ? true
        : false;
    const diffInMs = Math.abs(
      (endDateEqualToday ? endDate : addDays(endDate, 1)) - startDate
    ); // obtiene la diferencia en milisegundos
    const diffInHours =
      diffInMs / (1000 * 60 * 60) +
      (endDateEqualToday ? new Date().getHours() + 1 : 0); // convierte los milisegundos a horas y si la fecha de cierre es hoy suma la cantidad de horas que han transcurrido
    const arrDataTemp = [];
    const objetoFinalconTodasLasHoras = {};
    for (let obj in dataToGraph) {
      let nuevoArreglo = [];
      if (dataToGraph[obj].data.length < diffInHours) {
        for (let index = 0; index < diffInHours; index++) {
          const fechaFormateada = formatDate(
            formatWithTimeZone(startDate, true, index + 4)
          );
          const _idTemp = obj.toString() + ":" + fechaFormateada;
          const objTemp = {
            fecha: fechaFormateada,
            _id: _idTemp,
            lastMeasure: 0,
            memory: 0,
            faltantes: 60,
          };
          arrDataTemp[index] = objTemp;
        }
        nuevoArreglo = arrDataTemp.map((b) => {
          const a = dataToGraph[obj].data.find((a) => a.fecha === b.fecha);
          return a ? a : b;
        });
      }
      nuevoArreglo.length !== 0
        ? (objetoFinalconTodasLasHoras[obj] = {
            name: obj,
            data: nuevoArreglo,
            location: dataToGraph[obj].location,
          })
        : (objetoFinalconTodasLasHoras[obj] = dataToGraph[obj]);
    }
    setDataToGraphFinal(objetoFinalconTodasLasHoras);
  }, [dataToGraph]);

  window.addEventListener('message', (event) => {
    if (event.origin === URL_RADAR) {
      const { accessToken } = event.data;
      setAccessToken(accessToken)
    }
  });

  if (hasAccessToken) {
    return (
      <div className="App App-body" style={{ flex: 5}}>
          <div>
            <Caledar setDateRange={setDateRange} dateRange={dateRange} />
          </div>
          <CompanyList setIdInCompany={setIdInCompany} accessToken={hasAccessToken}/>
          <div style={isMobile?{width: "100%", marginRight:50 }:{width: "70%"}}>
            <div style={{ width: "100%"}}>
              {Chart(dataToGraphFinal)}
            </div>
          </div>
          <div style={{ flex: 4 }}></div>
      </div>
    );
  }
}

export default App;
