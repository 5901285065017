import React from "react";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

const MyDatePicker = (prop) => {
  const [startDate, endDate] = prop.dateRange;

  const clearDateRange = () => {
    prop.setDateRange([new Date(), null]);
  };

  return (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", marginTop: "25px" }}>
      <DatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        maxDate={
          addDays(startDate, 27) > new Date()
            ? new Date()
            : addDays(startDate, 27)
        }
        onChange={(update) => {
          prop.setDateRange(update);
        }}
        dateFormat="dd/MM/yyyy"
        className="custom-datepicker"
      />

      <div>
        <button style={{ backgroundColor: "#0577B3", color: "#fff", border: "0", padding: ".2rem 1rem", marginLeft: "10px" }} onClick={clearDateRange}>
          Borrar
        </button>
      </div>
    </div>
  );
};

export default MyDatePicker;
