import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

//props.data = const data = {2328: {name: "Test",data: [{ fecha: "01-03-2023", Normal: 30, Memoria: 15, Faltantes: 55 },]}}
const MyChart = (props) => {
  const CHART_COLORS = [
    "#56af89",
    "#f1b351",
    "#ea7e46",
    "#152f4a",
    "#2d4f86",
    "#00577b",
    "#a9cfdb",
  ];

  // Transformar los datos para que puedan ser utilizados por react-chartjs-2
  const datasets = Object.keys(props.data[0]) // Obtenemos las keys de cada objeto, para obtener las etiquetas de cada barra
    .filter((key) => key !== "fecha" && key !== "_id") // Nos aseguramos que la etiqueta no sea 'fecha'
    .map((label, index) => {
      const labelTemp = label;
      if (label === "memory") {
        label = "Dato desde SD";
      } else if (label === "lastMeasure") {
        label = "Dato normal";
      } else if (label === "faltantes") {
        label = "Dato faltante";
      }

      return {
        // Creamos los datasets para cada etiqueta
        label,
        data: props.data.map((item) => item[labelTemp]), // Obtenemos los datos para cada etiqueta
        backgroundColor: CHART_COLORS[index % CHART_COLORS.length], // Seleccionamos un color del array CHART_COLORS, de forma cíclica
        hidden: false,
        stack: 1,
      };
    });

const options = {
    responsive: true,
    scales: {
      x: {
        type: "category",
        labels: props.data.map((item) => item.fecha),
        title: {
          display: true,
          text: "Fecha",
        },
      },
      y: {
        title: {
          display: true,
          text: "Cantidad de cuentas",
        },
      },
    },
    legend: {
      position: "bottom",
      labels: {
        boxWidth: 10,
        usePointStyle: true,
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
        mode: "index",
      },
    },
  };

  return (
    <>
      <Bar
        data={{
          datasets,
        }}
        options={options}
      />
    </>
  );
};

export default MyChart;
