import React, { useState, useEffect } from "react";
import { COMPANY_LIST } from "../constants/api";

function DropdownList(prop) {
  const [options, setOptions] = useState([null]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [allCompanyData, setAllCompanyData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(COMPANY_LIST, { headers: { Authorization: `Bearer ${prop.accessToken}` } });
        const data = await response.json();
        const allCompanyArr = [null];
        const allCompanyDataObj = {};

        data.message.forEach((item, index) => {
          const { _id: id, companyName, sensors } = item;
          allCompanyArr[index + 1] = id + companyName;
          allCompanyDataObj[id] = { companyName, sensors };
        });        
  
        setOptions(allCompanyArr);
        setAllCompanyData(allCompanyDataObj);
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData();
  }, []);
  

  useEffect(() => {
    if (selectedOption && selectedOption.length > 0) {
      const allSensors = allCompanyData[selectedOption].sensors;

      const allSensorsList = [];
      let index = 0;

      for (let sensor in allSensors) {
        allSensorsList[index] = [sensor, allSensors[sensor]];
        index++;
      }
      prop.setIdInCompany({ message: allSensorsList });
    }
  }, [selectedOption]);

  const handleChange = (event) => {
    if (event.target.value !== 'Seleccione una Compañía') {
      setSelectedOption(event.target.value);
    } else {
      setSelectedOption([]);
    }
  };

  const optionsName = () => {
    const optionsResult = [null];
    options.forEach((option, index) => {
      optionsResult[index] = option
        ? [option.slice(0, 24), option.slice(24, option.length)]
        : [null, 'Seleccione una Compañía'];
    });

    return optionsResult.sort((a, b) => (a[0] && b[0]) && a[1].localeCompare(b[1]));
  };

  return (
    <div>
      <select value={selectedOption.toString()} onChange={handleChange}>
        {optionsName().map((option) => (
          <option key={option} value={option[0]}>
            {option[1]}
          </option>
        ))}
      </select>
    </div>
  );
}

export default DropdownList;
